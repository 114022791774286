html {
    overflow-x: hidden;
}

.img-faces {
    position: relative;
}

.img-faces-cejas {
    position: absolute;
    left: 0;
    margin: 16.5% 0 0 33.5%;
    width: 21.5%;
    z-index: 3;
}
.img-faces-parpado {
    position: absolute;
    left: 0;
    margin: 17.3% 0 0 11%;
    width: 14.5%;
    z-index: 3;
}

.eyes-container {
    width: 60%;
    position: absolute;
    display: flex;
    margin: -21% 12%;
    z-index: 1;
}

.eye {
    width: 5.3%;
}

.right-eye {
    margin-left: 11%;
}
.pomo-eyes {
    margin: -20% 39% !important;
}

.pomo-right-eye {
    margin-left: 12.5% !important;
}

.tarro-eyes {
    margin: -21.5% 76.3% !important;
}

.tarro-right-eye {
    margin-left: 10.5% !important;
}

@media only screen and (max-width: 600px) {
    .us-container {
        padding-top: 11%;
    }
}