/*GRID BUILDER*/
#grid-builder {
    background-color: lightgray;
    width: 100%;
    height: 100%;
    /*margin-top: -1.1%;*/
    padding: 1% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#grid-container {
    width: 80%;
}

/* GRID ROW*/

.grid-row {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 3% 0;
    padding: 0 3%;
    /*box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;*/
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;*/
    box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
}


.add-element-button {
    font-size: 2em;
    cursor: pointer;
    border: none;
    margin: 0 1%;
}

.grid-row-elements-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* GRID ELEMENT*/

.grid-element-container {
    width: 100%;
    margin: 1%;
}

.grid-element {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

