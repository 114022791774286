
.project-container {
    /*bac*/
    width: 100%;
    height: 100%;
    min-height: 100vh !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project-video {
    padding: 2%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.prueba {
    width: 70vw;
    height: 70vh;
}

.project-description {
    margin-top: 4%;
    width: 70vw;
}

.project-iframe {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 33.75vw;
    width: 60vw;
}

.project-cross {
    margin-right: 57.5%;
    cursor: pointer;
    width: 3%;
}

@media only screen and (max-width: 600px) {
    .project-cross {
        margin-right: 55%;
        width: 7%;
    }
    .project-container {
        padding-top: 19% !important;
    }
}
