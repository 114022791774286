.header {
    position: fixed;
    display: flex;
    flex-direction: row;
    height: 3rem;
    justify-content: space-between;
    width: 97%;
    margin-top: 1.5%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    z-index: 7;
}

.opaque {
    opacity: 1;
}

.logo-image {
    height: 9vw;
    margin-top: -2.2vw;
    margin-left: 0.2vw;
}

.hidden {
    display: none;
}

#logo-animation {
    height: 5.5vw;
}

.logo {
    cursor: pointer;
}

.instagram {
    cursor: pointer;
    height: 100%;
}

.header-button {
    height: 100%;
    color: white;
    cursor: pointer;
    z-index: 7;
}

.menu-option {
    margin-top: 10vh;
    color: white;
    cursor: pointer;
    font-family: 'ChowFont', serif;
    letter-spacing: .5rem;
    width: 10em;
}

.hide {
    animation: growUp 200ms ease-in-out forwards;
    transform-origin: top center;
}

.show {
    animation: growDown 500ms ease-in-out forwards;
    transform-origin: top center;
    visibility: visible;
}

.menu-options {
    padding-top: 10vh;
    height: 102vh;
    width: 100vw;
    font-size: 2em;
    position: fixed;
    justify-content: center;
    align-items: center;
    text-align: -webkit-center;
    z-index: 6;
    background-color: rgba(139, 0, 0, 0.95);
}

.menu-option:hover, .button-selected {
    text-decoration: overline;
}

@media only screen and (max-width: 600px) {
    .logo-image {
        margin-top: -3.2vw;
        margin-left: 1vw;
        height: 20vw;
    }

    .menu-options{
        padding-top: 0;
    }

    .header {
        margin-top: 2.5%;
    }
}

@keyframes growUp {
    0% {
        transform: scaleY(1)
    }
    100% {
        transform: scaleY(0)
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    100% {
        transform: scaleY(1)
    }
}