.footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 7vw;
    color: white;
    align-items: center;
    z-index: 99999;
    background: url('https://d2njbbkhc1pb2y.cloudfront.net/public/resources/FUEGO.png'), linear-gradient(0deg, rgba(255, 0, 0, 1), rgba(0, 0, 0, 0.4682247899159664) 88%) repeat-x center;
    background-size: contain;
    vertical-align: bottom;
}

.footer-icon {
    margin-right: 5%;
    cursor: pointer;
    color: white;
    font-size: 3vw;
}

#footer-image {
    height: 70%;
}

.logo-image-footer {
    height: 100%;
}

#footer-contact {
    margin-left: 3vw;
    width: 12vw;
    align-items: center;
    font-weight: 500;
    /*font-size: 3vw;*/
}

#footer-media {
    display: flex;
    flex-direction: row;
    margin-right: 3vw;
    width: 12vw;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .footerContainer {
        height: 20vw;
        width: unset;
    }

    #footer-contact {
        width: unset;
        font-size: 2vw;
    }

    .logo-image-footer {
        /*margin-top: auto;*/
        /*margin-bottom: auto;*/
        margin-top: 1vh;
        height: 90%;
    }

    #footer-media {
        width: unset;
        /*height: 9vw;*/
    }

    .footer-icon {
        font-size: 7vw;
        margin-right: 2vw;
    }
}