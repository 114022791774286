body, html {
    background-color: black;
}
* {
    margin: 0;
}

#second {
    height: 60vh; /* Viewport-relative units */
    width: 100vw;
    margin-left: 0;
}

.video-container {
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
}