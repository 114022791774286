.formControl {
    width: 80%;
    margin: 10% 1% 1%;
}

.imageLoaded {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.margin-top-label {
    /*margin-top: 5%;*/
}

.box-new-element-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: #282c34 1px solid;
    border-radius: 5px;
    margin: 2% auto;
    max-width: 50%;
    min-height: 50vh;
}

.container-new-element-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 100%;
}

.select-input {
    width: 30%;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: #282c34 1px solid;
    border-radius: 5px;
    margin: 2% auto;
    padding: 2%;
    max-width: 30%;
    min-height: 20vh;
}