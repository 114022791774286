
.horizontal-gifs-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}

.vertical-gifs-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}

.vertical-gif{
    margin-bottom: 5%;
}

#in-project-video{
    height: 25.3vw;
    width: 45vw;
    margin-bottom: 5%;
}

#diabla-proceso{
    width: 100%;
    margin-right: 3%;
}

#documentales-proceso-1 {
    margin-right: 15%;
    width: 100%;
}
#documentales-proceso-2 {
    width: 100%;
}

#polvo-proceso-1 {
    width: 50%;
    margin-right: 3%;
}

#los-sonidos-proceso-1 {
    width: 90%;
    margin-right: 4%;
}

#ginebra-main-img {
    margin-top: 3%;
    width: 35%;
}